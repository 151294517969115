import React, { useEffect, useState } from 'react';
import { FiChevronLeft, FiPlusCircle } from 'react-icons/fi';
import { HiLightBulb } from 'react-icons/hi';
import { navigate, useParams } from '@reach/router';
import { useFormik } from 'formik';
import 'tippy.js/dist/tippy.css';
import EssayCover from 'app/images/placeholders/show-course.jpg';
import { SimpleModal } from 'app/components/Modal';
import { useClient } from 'jsonapi-react';
import TextareaAutosize from 'react-autosize-textarea';
import toast from 'react-hot-toast';
import TabNavBar from './TabNavBar';
import Timeline from '../Timeline';
import styles from './styles.module.scss';
import Competence from './Competence';
import BreadCrumbs from 'app/components/BreadCrumbs';
import imgTia from 'app/components/TIA/img/tia-avatar.png';
import Avatar from 'app/components/Avatar';
import lampIcon from 'app/images/icons/lamp-icon.png';
import { statusBadgeColor, statusBadgeText } from '../utils/BadgeEssay';
import moment from 'moment';
import Loader from 'app/components/loader';
import heic2any from 'heic2any';
import { t } from 'i18next';

const MarkModalContentComponent = ({ content }) => {
  return (
    <>
      <span className="widget__title">{content.title}</span>
      <p className="u-mt-3">{content.body}</p>
    </>
  );
};

export default function EssayConsult({ essayId, studentId, uri }) {
  const [show, setShow] = useState(false);
  const [studentEssay, setStudentEssay] = useState({});
  const client = useClient();
  const [parsedText, setParsedText] = useState(null);
  const [selectedTab, setSelectedTab] = useState('correcao');
  const [loading, setLoading] = useState(false);
  const [essay, setEssay] = useState({});
  const [openMarkModal, setOpenMarkModal] = useState(false);
  const [submissions, setSubmissions] = useState([]);
  const [activeSubmission, setActiveSubmission] = useState(Number(studentId));
  const [markModalContent, setMarkModalContent] = useState(null);

  const fetchData = () => {
    setLoading(true);
    const essayFetch = client.fetch(['essays', essayId]);
    const submissionsFetch = client.fetch([`/essays/${essayId}/essay_students`]);
    const studentEssayFetch = client.fetch(`/essays/${essayId}/essay_students/${studentId}`);

    Promise.allSettled([essayFetch, submissionsFetch, studentEssayFetch]).then(([essayResponse, submissionsResponse, studentEssayResponse]) => {
      if (essayResponse.value.error) {
        toast.error('Erro ao buscar dados da redação');
      } else {
        setEssay(essayResponse.value.data);
      }

      if (submissionsResponse.value.error) {
        toast.error('Erro ao buscar envios da redação');
      } else {
        setSubmissions(submissionsResponse.value.data);
      }

      if (studentEssayResponse.value.error) {
        toast.error('Erro ao buscar redação enviada');
      } else {
        setStudentEssay(studentEssayResponse.value.data);
      }
      setLoading(false);
    });
  };

  const getStudentEssay = async () => {
    const { data, error } = await client.fetch(`/essays/${essayId}/essay_students/${studentId}`);
    if (error) {
      toast.error('Erro ao buscar redação');
    } else {
      setStudentEssay(data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const convertHeicOrHeifToJpg = async imageUrl => {
    if (!imageUrl) {
      return null;
    }
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const convertedBlob = await heic2any({ blob });

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;

        setStudentEssay(prevEssay => ({
          ...prevEssay,
          essay_image: { url: base64data }
        }));
      };
      reader.readAsDataURL(convertedBlob);
    } catch (error) {
      console.error('Erro ao converter imagem HEIC:', error);
    }
  };

  const switchDigital = useFormik({
    initialValues: {
      digital: true,
      competence: 'c1',
      points: 180
    }
  });

  useEffect(() => {
    if ((studentEssay && studentEssay?.status === 'annulled') || studentEssay?.status === 'annulled_by_teacher') {
      switchDigital.setFieldValue('digital', false);
    }

    if (studentEssay && studentEssay.essay_image && studentEssay.essay_image.url) {
      if (studentEssay.essay_image.url.includes('.heic') || studentEssay.essay_image.url.includes('.heif')) {
        convertHeicOrHeifToJpg(studentEssay.essay_image.url).then(url => {
          setStudentEssay(prevEssay => ({
            ...prevEssay,
            essay_image: { url }
          }));
        });
      }
    }
  }, [studentEssay]);

  useEffect(() => {
    getStudentEssay();
  }, [studentId]);

  const competencies = [
    { name: 'Competencia 1', description: 'Domínio da Norma Culta da Língua Portuguesa' },
    { name: 'Competencia 2', description: 'Compreensão e Aplicação Interdisciplinar do Tema Proposto' },
    { name: 'Competencia 3', description: 'Seleção e Organização Lógica de Argumentos' },
    { name: 'Competencia 4', description: 'Construção Coesa e Coerente da Argumentação' },
    { name: 'Competencia 5', description: 'Proposição de Intervenção Respeitosa aos Direitos Humanos' }
  ];

  useEffect(() => {
    renderTextWithMarks();
  }, [studentEssay]);

  const renderTextWithMarks = () => {
    const corrections = studentEssay?.corrections?.sort((a, b) => a.start_index - b.start_index);
    const tempElement = document.createElement('div');
    tempElement.innerHTML = studentEssay?.body;
    const text = tempElement.textContent;

    tempElement.remove();

    if (!corrections || corrections.length === 0) {
      setParsedText(<>{text}</>);
    } else {
      const selections = corrections.filter(correction => correction.start_index !== -1);
      const elements = [];
      let acumulator = '';
      let selectionIndex = 0;
      let counter = 0;

      text?.split('').forEach((c, i) => {
        if (i >= selections[selectionIndex]?.start_index + counter && i < selections[selectionIndex]?.end_index + counter) {
          acumulator += c;
        } else if (i === selections[selectionIndex]?.end_index + counter) {
          const currentIndex = selectionIndex;
          elements.push(
            <mark
              key={i}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setOpenMarkModal(true);
                setMarkModalContent({
                  title: 'Marcação',
                  body: selections[currentIndex].body
                });
              }}
            >
              {acumulator}
            </mark>
          );
          acumulator = '';
          elements.push(c);

          if (selectionIndex < selections.length - 1) {
            selectionIndex += 1;
          }
        } else {
          elements.push(c);
        }
      });

      if (acumulator) {
        elements.push(
          <mark
            key={text.length}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setOpenMarkModal(true);
              setMarkModalContent({
                title: 'Marcação',
                body: selections[selectionIndex].body
              });
            }}
          >
            {acumulator}
          </mark>
        );
      }

      setParsedText(<>{elements}</>);
    }
  };

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: '/redacao',
        name: 'Redação',
        isActive: false
      },
      {
        route: uri,
        name: essay.topic,
        isActive: true
      }
    ]
  };

  const checkScore = score => {
    if (score <= 333) {
      return 'danger';
    } else if (score > 333 && score <= 666) {
      return 'warning';
    } else if (score > 666) {
      return 'success';
    }
  };

  const validSubmissions = submissions.filter(item => item.status !== 'minimum_length_not_satisfied');

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      {(statusBadgeText[studentEssay?.status] === 'Correção por Professor' || statusBadgeText[studentEssay?.status] === 'Correção por IA' || statusBadgeText[studentEssay?.status] === 'Anulada') && (
        <TabNavBar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          essayStatus={studentEssay?.status}
        />
      )}

      {loading ? (
        <Loader />
      ) : (
        <>
          {selectedTab === 'correcao' && (
            <div className={`${styles['page-content']} ${studentEssay?.status === 'annulled' || studentEssay?.status === 'annulled_by_teacher' ? styles['page-content__flex-start'] : ''}`}>
              <div className={styles.aside}>
                {essay?.['allow-multiple-submissions'] ? (
                  <div className="essay-widget">
                    {/* Aguardar definição da regra de quantidade de envios */}
                    {validSubmissions.length < 2 && (
                      <div style={{ borderBottom: '1px solid #ECE9F8' }}>
                        <button
                          className={`btn btn--outline btn--small ${styles['new-send-btn']}`}
                          onClick={() => navigate(`/redacao/enviar/${essayId}`)}
                        >
                          <FiPlusCircle />
                          Novo envio
                        </button>
                      </div>
                    )}
                    <Timeline
                      submissions={submissions}
                      activeSubmission={activeSubmission}
                      setActiveSubmission={setActiveSubmission}
                      essayId={essayId}
                    />
                  </div>
                ) : (
                  <div className="essay-widget">
                    <div className={styles['score-card']}>
                      <p className={`${styles['score-card-value']} ${styles[`score-card-value--${checkScore(studentEssay.grade)}`]} u-mb-0`}>{studentEssay.grade}</p>
                      <span>pontos</span>
                    </div>
                    <div className={styles['aside-footer']}>
                      <span className={`badge badge--tiny ${statusBadgeColor[essay?.status]}`} >{statusBadgeText[essay?.status]}</span>
                      <p className="u-mb-0">em {moment(studentEssay?.['delivery_date']).format('DD/MM/YYYY')}</p>
                    </div>
           
                    {studentEssay?.status === 'annulled' || studentEssay?.status === 'annulled_by_teacher' ? (
                      <div className={`${styles['aside-footer']} ${styles['aside-footer-border']} u-mt-4`}>
                        <h2>Avaliação ortográfica</h2>
                        <span className={`square-tag square-tag--sm square-tag--${studentEssay?.['grammar_quality'] === 'Boa' ? 'success' : studentEssay?.['grammar_quality'] === 'Mediana' ? 'warning' : 'danger'}`}>
                          {studentEssay?.['grammar_quality']}
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
              <div className={styles['page-content-main']}>
                <div className={` ${styles['tia-container']} u-mb-4`}>
                  <Avatar
                    className={styles['tia-container-avatar']}
                    src={imgTia}
                    alt="T.I.A"
                    data-tooltip-id="tia"
                    data-tooltip-place="top-start"
                    data-tooltip-variant="light"
                  />
                  <div className={styles['tia-container-text-balloon']}>
                    <div className="widget__header">
                      <div className={styles['tia-container-title']}>
                        <h2>Sugestões da T.I.A para melhorar sua redação</h2>
                        <img
                          src={lampIcon}
                          className={styles['tia-container-title-icon']}
                        />
                      </div>
                      <p>{studentEssay?.['suggestion_to_improve']}</p>
                    </div>
                  </div>
                </div>

                <div className="skill-evaluation">
                  <div className="skill-evaluation__essay-header">
                    <h2 className="skill-evaluation__essay-title">Sua redação</h2>
                    {studentEssay?.status !== 'annulled' || studentEssay?.status !== 'annulled_by_teacher' ? (
                      <div className="form__check form__switch u-mb-0">
                        <input
                          className="form__check-input"
                          id="digital"
                          name="digital"
                          type="checkbox"
                          checked={switchDigital.values.digital}
                          onChange={() => {
                            switchDigital.setFieldValue('digital', !switchDigital.values.digital);
                          }}
                        />
                        <label htmlFor="digital">{switchDigital.values.digital ? 'Digital' : 'Cursiva'}</label>
                      </div>
                    ) : null}
                  </div>

                  {switchDigital?.values?.digital ? (
                    <div className="skill-evaluation__essay-body">
                      <div
                        className="skill-evaluation__essay-text"
                        style={{ overflow: 'visible' }}
                      >
                        <span
                          className="skill-evaluation__essay-mark"
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          {' '}
                          {parsedText}{' '}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <img
                        className="u-cursor-pointer"
                        src={studentEssay?.essay_image?.url}
                        alt="prova digitalizada"
                        onClick={() => setShow(true)}
                        style={{ objectFit: 'contain' }}
                      />
                      <SimpleModal
                        show={show}
                        onClose={() => setShow(false)}
                        contentClassName="simple-modal__content--lg"
                      >
                        <img
                          src={studentEssay?.essay_image?.url}
                          alt="prova digitalizada"
                        />
                      </SimpleModal>
                    </>
                  )}
                </div>
              </div>
              {studentEssay?.status !== 'annulled' || studentEssay?.status !== 'annulled_by_teacher' ? (
                <div className={`${styles.aside} ${styles['aside-competences']}`}>
                  {studentEssay?.['grammar_quality'] && (
                    <div className="essay-widget u-mb-4">
                      <h2 className="widget__title u-mb-2">Avaliação ortográfica</h2>
                      <span className={`square-tag square-tag--md square-tag--${studentEssay?.['grammar_quality'] === 'Boa' ? 'success' : studentEssay?.['grammar_quality'] === 'Mediana' ? 'warning' : 'danger'}`}>
                        {studentEssay?.['grammar_quality']}
                      </span>
                    </div>
                  )}

                  <div className="essay-widget">
                    <h2 className="widget__title">Avaliação de competências</h2>
                    {studentEssay?.competencies?.map((competence, i) => (
                      <Competence
                        competence={competence}
                        item={competencies[i]}
                      />
                    ))}
                  </div>
                </div>
              ) : null}
              {
                <SimpleModal
                  show={openMarkModal}
                  onClose={() => setOpenMarkModal(false)}
                  contentClassName="simple-modal__content--md"
                >
                  <MarkModalContentComponent content={markModalContent} />
                </SimpleModal>
              }
            </div>
          )}

          {selectedTab === 'sobre' && (
            <div className="skill-evaluation">
              <div className="course-about__header">
                <h1 className="course-about__title">Enunciado</h1>

                <p
                  className="course-about__description"
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  {essay?.statement}
                </p>

                <p className="course-about__description">
                  Tema: {essay?.topic}
                  <br />
                  {/* Título: <strong>{essay?.title}</strong> */}
                </p>
              </div>

              <div className="course-about__body">
                <h2 className="course-about__title">Textos motivadores</h2>
                {essay?.['essay-motivator-texts']?.map((item, index) => {
                  return (
                    <article key={index}>
                      <h3 className="course-about__subtitle">Texto {index + 1}</h3>
                      <p
                        className="course-about__description"
                        dangerouslySetInnerHTML={{ __html: item.body }}
                      />
                      {/* <p className="course-about__description">Disponível em: https://brasilescola.uol.com.br/portugues/preconceito-linguistico.htm. Acesso em: 19 ago. 2022 (adaptado).</p> */}
                    </article>
                  );
                })}
              </div>
            </div>
          )}

          {selectedTab === 'competencias' && (
            <div className={styles['competences-tab']}>
              <div
                className="essay-widget u-mb-4"
                style={{ width: '300px' }}
              >
                <h2 className="widget__title u-mb-2">Avaliação ortográfica</h2>
                <span className="square-tag square-tag--md square-tag--success">Boa</span>
              </div>
              <div className={`${styles.competences}`}>
                <h2 className="widget__title  u-mb-3">Avaliação de competências</h2>
                <div className={styles['competences-list']}>
                  {studentEssay?.competencies?.map((competence, i) => (
                    <Competence
                      competence={competence}
                      item={competencies[i]}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </main>
  );
}
